<script setup>
import { useTranslation } from "@/composables/useTranslation";
import { useAccount } from "~/composables/useAccount";
import { postMessageToParentFrame } from "@/composables/useStudy/study.utils";

const { fetchTransl, setLocale } = useTranslation();

const menuLeftDialog = ref(false);
const menuRightDialog = ref(false);
const registrPendingDialog = ref(false);
const registrSuccessDialog = ref(false);

const dialogSelectedTab = ref("login");
const selectedCourse = ref(null);

onGlobal("menu-left-mobile", () => {
  menuLeftDialog.value = true;
});

onGlobal("menu-right-mobile", () => {
  menuRightDialog.value = true;
});

onGlobal("request.onsubmit", () => {
  // sendYMEvent();
});

onMounted(() => {
});
</script>
<template>
  <slot></slot>

  <!-- <v-menu-mobile-left-dialog
      v-model:dialog="menuLeftDialog"
    ></v-menu-mobile-left-dialog>
    <v-menu-mobile-right-dialog
      v-model:dialog="menuRightDialog"
      :selectedTab="dialogSelectedTab"
      :selectedCourse="selectedCourse"
    ></v-menu-mobile-right-dialog>
    <v-user-inform-dialog v-model:dialog="registrSuccessDialog">
      <template #title>{{ transl("Texts:Congratulations") }}</template>
      {{ transl("Texts:You has successfully leave your registration apply") }}
    </v-user-inform-dialog>
    <v-user-inform-dialog v-model:dialog="registrPendingDialog">
      <template #title>Спасибо за регистрацию</template>
      <p>
        Спасибо за регистрацию на нашем сайте Книга Книг! Мы хотим быть уверены, что вы
        получите наилучший опыт обучения, поэтому подберем вам наставника в зависимости от
        вашего местоположения.
      </p>
      <p>
        Пожалуйста, следите за вашей электронной почтой и номером телефона. Ваш наставник
        свяжется с вами и вы сможете полноценного обучаться в нашей школе Библии.
        Благословений Вам.
      </p>
    </v-user-inform-dialog> -->
</template>
